<template>
    <div class="bg-ide-gray relative">
        <div
            v-if="!fileContent"
            class="h-full w-full flex items-center justify-center">
            <atEmblemSvg class="text-ide-gray-900 fill-current h-48" />
        </div>
        <div
            ref="editorContainer"
            v-if="fileContent"
            class="absolute left-0 top-0 h-full right-0 bottom-0">
        </div>
    </div>
</template>

<script>
import * as monaco from 'monaco-editor';

import { EventBus } from '@/utils';

import debounce from 'lodash.debounce';

import atEmblemSvg from '@/assets/img/atEmblem.svg';

// eslint-disable-next-line no-restricted-globals
self.MonacoEnvironment = {
    getWorkerUrl(moduleId, label) {
        if (label === 'json') {
            return '/json.worker.js';
        }
        if (label === 'css') {
            return '/css.worker.js';
        }
        if (label === 'html') {
            return '/html.worker.js';
        }
        if (label === 'typescript' || label === 'javascript') {
            return '/ts.worker.js';
        }
        return '/editor.worker.js';
    },
};

const languageConfig = {
    js: 'javascript',
    json: 'json',
    html: 'html',
    php: 'php',
    cs: 'csharp',
    ino: 'c',
    cpp: 'cpp',
    h: 'c',
    py: 'python',
    scala: 'java',
    java: 'java',
    kt: 'java',
    gradle: 'java',
    sbt: 'java',
    xml: 'xml',
    yml: 'yaml',
    yaml: 'yaml',
    md: 'markdown',
    README: 'markdown',
    sql: 'mysql',
    Dockerfile: 'dockerfile',
    sh: 'shell',
    ksh: 'shell',
    SETUP: 'shell',
    ini: 'ini',
    service: 'ini',
    properties: 'ini',
};

let EDITOR_REF = {};

export default {
    name: 'Editor',

    props: {
        file: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            editorCreated: false,
            fileType: null,
            fileName: null,
            fileContent: null,
            shouldEmitFileUpdate: false,
        };
    },

    created() {
        this.registerBusListeners();
    },

    beforeDestroy() {
        this.destroyEditor();
        this.cancelBusListeners();
    },

    watch: {
        file(newFile) {
            const fileName = newFile.name.split('/').pop();
            const fileType = fileName.split('.').pop();

            this.shouldEmitFileUpdate = false;
            this.fileName = fileName;
            this.fileType = fileType;
            this.fileContent = newFile.content;

            if (this.editorCreated === false) {
                this.$nextTick(() => {
                    this.createEditor();
                    this.updateEditorValue();
                });
            } else {
                this.updateEditorValue();
            }
        },
    },

    methods: {
        createEditor() {
            const editorEl = this.$refs.editorContainer;
            const liveEditor = monaco.editor.create(editorEl, {
                theme: 'vs-dark',
                minimap: {
                    enabled: false,
                },
            });
            EDITOR_REF.liveEditor = liveEditor;
            this.registerEditorEvents();
            this.editorCreated = true;
        },

        destroyEditor() {
            const { liveEditor } = EDITOR_REF;
            if (liveEditor === undefined) return;
            liveEditor.getModel().dispose();
            liveEditor.dispose();
            EDITOR_REF = {};
        },

        updateEditorValue() {
            const { liveEditor } = EDITOR_REF;
            if (liveEditor === undefined) return;
            const fileExtension = this.fileName.split('.').pop();
            const fileLanguage = languageConfig[fileExtension];
            liveEditor.setValue(this.fileContent);
            monaco.editor.setModelLanguage(liveEditor.getModel(), fileLanguage);
        },

        registerEditorEvents() {
            const { liveEditor } = EDITOR_REF;
            if (liveEditor === undefined) return;
            liveEditor.onDidChangeModelContent(this.onModelContentChange);
        },

        onModelContentChange: debounce(function fx() {
            if (this.shouldEmitFileUpdate) {
                const { liveEditor } = EDITOR_REF;
                const newContent = liveEditor.getModel().getValue();
                this.$emit('update-file', newContent);
            } else {
                this.shouldEmitFileUpdate = true;
            }
        }, 500),

        resizeEditor: debounce(() => {
            const { liveEditor } = EDITOR_REF;
            if (liveEditor === undefined) return;
            requestAnimationFrame(() => {
                liveEditor.layout();
            });
        }, 500, { leading: true }),

        registerBusListeners() {
            EventBus.$on('resizeEditor', this.resizeEditor);
            window.addEventListener('resize', this.resizeEditor);
        },

        cancelBusListeners() {
            EventBus.$off('resizeEditor', this.resizeEditor);
            window.removeEventListener('resize', this.resizeEditor);
        },
    },

    components: {
        atEmblemSvg,
    },
};
</script>
